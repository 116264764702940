import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The model of Gondwana dispersal developed by Earthworks over many years has
specific  consequences for the sequence of events by which India's passive
margins developed. These  events are summarised in a new publication from the
Government of India's Department of  Science and Technology. `}</p>
    <p>{`The article appeared in the August 2010 edition of the Newsletter 'Deep
Continental Studies'. A  reprint of the publication may be downloaded `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      